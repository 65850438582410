/* eslint-disable import/no-cycle */
import React from 'react';
import { AxiosError } from 'axios';
import { action, makeObservable } from 'mobx';
import { RootStore } from './RootStore';
import BaseStore from './base-stores/BaseStore';
import ErrorModal from '../features/modals/ErrorModal';
import { AxiosErrorData } from '../Types';

export default class ErrorStore extends BaseStore {
  customHandledErrors: string[] = ['vehicleNotFoundErrorCode', 'NoIdentifierFoundByPartner'];

  handleError = (error: AxiosError<AxiosErrorData>) => {
    const errorHeader = this.rootStore.contentStore.cmsStore.getErrorMessage('SomethingWentWrong')!;

    if (error.response && error.response.data) {
      const { errorCode } = error.response.data;
      const contentErrorMessage = this.rootStore.contentStore.cmsStore.getErrorMessage(errorCode);

      if (!this.customHandledErrors.includes(errorCode)) {
        const errorMessage =
          contentErrorMessage ||
          this.rootStore.contentStore.cmsStore.getErrorMessage('DefaultErrorMessage');
        this.rootStore.modalStore.openModal(
          <ErrorModal header={errorHeader} message={errorMessage!} />
        );
      }
    }
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      handleError: action
    });
  }
}
