export const downloadExcel = (fileName: String, data: any) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  const blobURL = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.download = `${fileName}.xlsx`;
  anchor.href = blobURL;
  anchor.click();
  window.URL.revokeObjectURL(blobURL);
};

export default { downloadExcel };
